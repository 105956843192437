import React from 'react';
import LOC from '../../Localization/Translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture, faMap, faSync, faFighterJet } from '@fortawesome/free-solid-svg-icons';

import './styles.css';

export default () => {

	const lang = LOC.getLanguage();

	return (
		<div className='background-color padding-bottom'>
			<div className='container section-padding'>

                <div className='padding-bottom-100'>
                    <h1 className='text-center home-header'>{lang.whatWeDo}</h1>
				</div>

				<div className='row no-gutters'>
					<div className='col-sm-12 col-md-6 leftBox box'>
						<div className='media'>
							<div className='media-left left-offset-40 right-offset-40'>
                                <FontAwesomeIcon icon={faFighterJet} size='4x' color='#FFA626' style={{minWidth: 80}}/>
							</div>
							<div className='media-body media-text right-offset-40'>
								<h3>{lang.prototyping} </h3>
								{lang.prototypingIntro}
								<br/>
							</div>
						</div>
					</div>

					<div className='col-sm-12 col-md-6 box'>
						<div className='media'>
							<div className='left-offset-40 right-offset-40'>
								<FontAwesomeIcon icon={faMap} size='4x' color='#FFA626' style={{minWidth: 80}}/>
							</div>
							<div className='media-body media-text right-offset-40'>
								<h3>{lang.strategy} </h3>
								{lang.strategyIntro}
								<br/>
							</div>
						</div>
					</div>
				</div>

				<div className='row no-gutters'>
					<div className='col-sm-12 col-md-6 leftBox bottomBox box'>
						<div className='media'>
							<div className='media-left left-offset-40 right-offset-40'>
								<FontAwesomeIcon icon={faSync} size='4x' color='#FFA626' style={{minWidth: 80}}/>
							</div>
							<div className='media-body media-text right-offset-40'>
								<h3> {lang.continiousdevelopment} </h3>
								{lang.continiousdevelopmentIntro}
								<br/>
							</div>
						</div>
					</div>

					<div className='col-sm-12 col-md-6 bottomBox box'>
						<div className='media'>
							<div className='media-left right-offset-40 left-offset-40'>
								<FontAwesomeIcon icon={faPlaneDeparture} size='4x' color='#FFA626' style={{minWidth: 80}}/>
							</div>
							<div className='media-body media-text right-offset-40'>
								<h3>{lang.innovationlaunch}</h3>
								{lang.innovationlaunchIntro}
								<br/>
                                { /*<span className='btn btn-primary color-white align-self-center custom-bottom'> {lang.innovationlaunchButton} </span> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
