import React from 'react';
import JobCard from './../../Components/JobCard/JobCard';
import './styles.css';
import { DataService } from '../../API/Dataservice';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';

export default (props: any) => {

    GoogleAnalytics.getInstance().hit(props.navigation.state.key);
    return (
        <div>
            <Helmet>
                <title> Work at MakeTek - Jobs & Internships </title>
                <link rel='canonical' href='https://www.maketek.nl/job-openings' />
            </Helmet>

            <div className='jumbotron jumbotron-fluid job-opening-jumbo orangeBackground'>
                <div className='container d-table' style={{ height: '100%' }}>
                    <div className='text-center d-table-cell align-middle'>
                        <div className='jobHeader'>ONE OF US, ONE OF US!</div>
                        <div className='jobSecondHeader'>Make Tek, Make Money</div>
                    </div>
                </div>
            </div>

            <div className=''>
                <h4 className='text-center home-header'>Vacancies</h4>
                <div className='mt-2 mb-2 text-center'> We are currently looking for applicants in the following fields. interested? send an email to jan@maketek.nl</div>
            </div>

            <div className='container'>
                <div className='row pageMargins'>
                    {
                        DataService.jobs().map((job) =>
                            <div key={job.id} className='col-sm-12 col-md-6 col-lg-4 jobCardMargins justify-content-sm-center'>
                                <JobCard
                                    job={job}
                                    imageAltText={job.alt}
                                    title={job.title}
                                    text={job.intro}
                                    buttonText={job.button}
                                    navigation={props.navigation}
                                />
                            </div>
                        )
                    }
                </div>
            </div>

            <div className=''>
                <h4 className='text-center home-header'>Internships</h4>
                <div className='mt-2 mb-2 text-center'> Are you a student looking for an internship, we are currently looking for interns. Interested? send an email to jan@maketek.nl</div>

            </div>

            <div className='container'>
                <div className='row pageMargins'>
                    {
                        DataService.internships().map((job) =>
                            <div key={job.id} className='col-sm-12 col-md-6 col-lg-4 jobCardMargins justify-content-sm-center'>
                                <JobCard
                                    job={job}
                                    imageAltText={job.alt}
                                    title={job.title}
                                    text={job.intro}
                                    buttonText={job.button}
                                    navigation={props.navigation}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            {/*
		<div>
			<div className='row no-gutters justify-content-center jumbotron paddedRow'>
				<div className='text-center'>
					<h3>Projects</h3>
					<div>
						We specialise in rapidly building web and mobile apps, using rapid prototyping.
					</div>

					<div className='btn btn-outline-dark job-button'>Check out some of our projects</div>
				</div>
			</div>
		</div>
        */}
        </div>
    );
};
