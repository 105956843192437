import * as React from 'react';
import ImageTextBox from './../../Components/ImageTextBox/ImageTextBox';
import WhatWeDo from './../../Components/WhatWeDo/WhatWeDo';
import LOC from '../../Localization/Translations';
import dthinking from './../../assets/images/Design-thinking-process.jpeg';
import plego from './../../assets/images/gears.png';
import plants from './../../assets/images/wd.png';
import tff from './../../assets/images/tff.png';
import logo from './../../assets/images/Mtek512x512.png';

import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics.web';
import './styles.css';

export default (props?: any) => {
    { console.log(props.x); }
    const lang = LOC.getLanguage();

    const jsonld2 = [
        {
            '@type': 'Service',
            'serviceType': lang.prototyping,
            'description': lang.prototypingIntro,
            'url': 'https://www.maketek.nl/services',
            '@id': 'https://www.maketek.nl/services/1',
            'Provider': {
                '@type': 'Organization',
                'name': 'MakeTek',
            },
        },
        {
            '@type': 'Service',
            'serviceType': lang.strategy,
            'description': lang.strategyIntro,
            'url': 'https://www.maketek.nl/services',
            '@id': 'https://www.maketek.nl/services/2',
            'Provider': {
                '@type': 'Organization',
                'name': 'MakeTek',
            },
        },
        {
            '@type': 'Service',
            'serviceType': lang.continiousdevelopment,
            'description': lang.continiousdevelopmentIntro,
            'url': 'https://www.maketek.nl/services',
            '@id': 'https://www.maketek.nl/services/3',
            'Provider': {
                '@type': 'Organization',
                'name': 'MakeTek',
            },
        },
        {
            '@type': 'Service',
            'serviceType': lang.innovationlaunch,
            'description': lang.innovationlaunchIntro,
            'url': 'https://www.maketek.nl/services',
            '@id': 'https://www.maketek.nl/services/4',
            'Provider': {
                '@type': 'Organization',
                'name': 'MakeTek',
            },
        },
    ];
    const jsonld = {
        '@context': 'http://schema.org/',
        '@type': 'Webpage',
        'name': 'MakeTek',
        'image': logo,
        'description': 'Software protototyping, strategy, and development',
        'url': 'https://www.maketek.nl/',
    };
    GoogleAnalytics.getInstance().hit(props.navigation.state.key);

    return (
        <div>

            <Helmet>
                <title> MakeTek - Home </title>
                <link rel='canonical' href='https://www.maketek.nl/'/>
                <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
                <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld2)}</script>
            </Helmet>

            <div className='landingBackground'>
                <div className='jumbotron jumbotron-fluid jumbo-custom'>
                    <div className='container container-custom d-table'>
                        <div className='d-table-cell align-middle'>
                            <h3 className='display-4 no-opacity'>{lang.slogan}</h3>
                            {/*<p className='lead'>{lang.subSlogan}</p>*/}

                            {/* Alternatively: */}
                            {/*<p className='lead'>{LOC.current.subSlogan}</p>*/}
                            {/*<span className='btn btn-outline-light rounded-button'>{lang.startHere}</span>*/}
                        </div>
                    </div>
                </div>
            </div>

            <WhatWeDo />

            <div className='padding-bottom-100 padding-top-100'>
                <h1 className='text-center home-header'>{lang.howWeDoIt}</h1>
            </div>

            <ImageTextBox
                imageLeft
                title={lang.designThinking}
                leadText=''
                text={lang.designThinkingText}
                buttonText={lang.designThinkingButton}
                image={dthinking}
                smallImage
            />

            <ImageTextBox
                imageLeft={false}
                title={lang.tekGears}
                leadText=''
                text={lang.tekGearsText}
                buttonText={lang.tekGearsButton}
                image={plego}
                smallImage
            />

            <div className='padding-bottom-100 padding-top-100 orangeBackground'>
                <h1 className='text-center home-header'>{lang.whatComesOut}</h1>
            </div>

            <ImageTextBox
                imageLeft
                title={lang.projectWd}
                leadText={lang.projectWdLead}
                text={lang.projectWdText}
                buttonText={lang.projectWdButton}
                image={plants}
            /> {/*link={ () => props.navigation.navigate('ProjectDetails', {id: 1}) }*/}

            <ImageTextBox
                imageLeft={false}
                title={lang.projectTff}
                leadText={lang.projectTffLead}
                text={lang.projectTffText}
                buttonText={lang.projectTffButton}
                image={tff}
            /> {/*link={() => props.navigation.navigate('ProjectDetails', { id: 2 })}*/}
        </div>
    );
};
