import React from 'react';
import './styles.css';
import ServicesIcons from '../../Components/ServicesIcons/ServicesIcons';
import ImageTextBox from '../../Components/ImageTextBox/ImageTextBox';
import network from '../../assets/images/board.jpg';
import analysis from '../../assets/images/meeting.jpg';
import cooperation from '../../assets/images/conference.jpg';
import prototype from '../../assets/images/prototype.jpg';
import LOC from '../../Localization/Translations';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics.web';
const lang = LOC.getLanguage();

import logo from './../../assets/images/Mtek512x512.png';

export default (props: any) => {
    GoogleAnalytics.getInstance().hit(props.navigation.state.key);

    const jsonld = {
        '@context': 'http://schema.org/',
        '@type': 'Webpage',
        'name': 'MakeTek - Services',
        'image': logo,
        'description': 'MakeTek offers a wide selection of software development & strategy services. Prototyping, lean software development, Continuous development, innovation launch, digital strategy.',
        'url': 'https://www.maketek.nl/services',
    };

    return (
        <div>
            <Helmet>
                <title> MakeTek - Development & Strategy Services  </title>
                <link rel='canonical' href='https://www.maketek.nl/services' />

                <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
            </Helmet>

            <div className='jumbotron jumbotron-fluid services-opening-jumbo orangeBackground' style={{ marginBottom: 0 }}>
                <div className='container d-table' style={{ height: '100%' }}>
                    <div className='text-center d-table-cell align-middle'>
                        <div className='jobHeader'>{lang.services}</div>
                        <div className='jobSecondHeader'>
                        </div>
                    </div>
                </div>
            </div>
            <div className='full-gray'>
                <div className='container pt-5 pb-5'>
                    <ServicesIcons developmentServices={true} />
                </div>
            </div>
            <ImageTextBox
                imageLeft
                title={lang.prototyping}
                leadText=''
                text={lang.prototypingIntro}
                image={prototype}
            />
            {/*
		<ImageTextBox
			title='Lean software development'
			leadText=''
			text='write write write'
			image={coding}
		/>
        */}

            <ImageTextBox
                title={lang.continiousdevelopment}
                leadText=''
                text={lang.continiousdevelopmentIntro}
                image={network}
            />
            <div className='full-gray'>
                <div className='container pt-5 pb-5'>
                    <ServicesIcons singularServices={true} />
                </div>
            </div>
            <ImageTextBox
                imageLeft
                title={lang.strategy}
                leadText=''
                text={lang.strategyIntro}
                image={analysis}
            />
            {/*
        <ImageTextBox
            imageLeft
            title='Security Scan'
            leadText=''
            text='Protect yourself, '
            image={security}
        />
        */}

            <ImageTextBox
                title={lang.innovationlaunch}
                leadText=''
                text={lang.innovationlaunchIntro}
                image={cooperation}
            />

        </div>
    );
};
