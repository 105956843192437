import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faFighterJet,
	faLaptopCode,
    faEye,
    faSync,
    faMap,
    faPlaneDeparture
} from '@fortawesome/free-solid-svg-icons';

interface IServicesIconsProps {
	developmentServices?: boolean;
	singularServices?: boolean;
}

import LOC from '../../Localization/Translations';
const lang = LOC.getLanguage();

export default (props: IServicesIconsProps) => {
	return (
		<div>
			{props.developmentServices &&
				<div className='row'>
					<h2 className='jobHeader text-center col-12 mb-4 mt-2 mb-5'>
						Development services
					</h2>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faFighterJet} size='6x' color='#FFA626'/>
						</div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                    {lang.prototyping}
						</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faLaptopCode} size='6x' color='#FFA626'/>
						</div>
						<h3 className='mt-2 mb-3 mt-3 text-center'>
							Lean software development
						</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
                    <div className='col-12 text-center'>
                        <FontAwesomeIcon icon={faSync} size='6x' color='#FFA626' />
						</div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                        {lang.continiousdevelopment}
						</h3>
					</div>
				</div>
			}

			{props.singularServices &&
				<div className='row'>
					<h2 className='jobHeader text-center col-12 mb-4 mt-2 mb-5'>
						Strategy services
					</h2>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faMap} size='6x' color='#FFA626'/>
						</div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                        {lang.strategy}
						</h3>
					</div>

					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faEye} size='6x' color='#FFA626'/>
						</div>
						<h3 className='mt-2 mb-3 mt-3 text-center'>
							Security Scan
						</h3>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
                        <div className='col-12 text-center'>
                        <FontAwesomeIcon icon={faPlaneDeparture} size='6x' color='#FFA626' />
                        </div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                        {lang.innovationlaunch}
						    </h3>
                    </div>
				</div>
			}
		</div>
	);
};
