import { default as jobs } from './data/Jobs.json';
import { default as internships } from './data/Interships.json';

export class DataService {

    public static jobs(id?: number): any {
        for (const job of jobs) {
            if (job.id === id) {
                return job;
            }
        }
        return jobs;
    }

    public static internships(id?: number): any {
        for (const internship of internships) {
            if (internship.id === id) {
                return internship;
            }
        }
        return internships;
    }
}
